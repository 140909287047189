.vp-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: clamp(1.2rem, 2vw, 2rem);
  color: #fff;
  background-color: #000;
}
.vp-container video {
  width: 100%;
  height: 100%;
  z-index: 1;
}
.vp-controls {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
  height: clamp(10rem, 15vw, 25rem);
  padding-bottom: 1rem;
  background-image: linear-gradient(transparent, #000);
  transition: opacity 300ms ease-out;
  z-index: 15;
}
.vp-controls.hide {
  opacity: 0;
  pointer-events: none;
}
.vp-controls__header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: clamp(3rem, 100%, 5rem);
  margin-top: auto;
}
.vp-controls__body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  padding: 0 2rem;
  gap: 2rem;
}

.vp-controls__body > div {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.vp-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 4rem;
  max-width: 12rem;
  height: 100%;
  font-size: clamp(1.2rem, 2vw, 2rem);
  color: #fff;
  background-color: transparent;
}
.vp-btn svg {
  width: clamp(2rem, 4vw, 4.5rem);
  height: 100%;
  transition: transform 200ms;
}
.vp-btn::before {
  content: attr(data-label);
  position: absolute;
  display: none;
  bottom: 100%;
  width: max-content;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms ease-out;
}
.vp-btn.label::before {
  display: block;
}
.vp-btn:hover svg {
  transform: scale(1.3);
}
.vp-btn:hover::before {
  opacity: 1;
}

.vp-progress {
  position: relative;
  width: 100%;
  height: 100%;
}
.vp-progress__range {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
.vp-progress__range--background,
.vp-progress__range--buffer,
.vp-progress__range--current,
.vp-progress__range--seek {
  position: absolute;
  width: 100%;
  height: 10%;
  border-radius: 50px;
}
.vp-progress__range--background {
  background-color: #858585;
}
.vp-progress__range--buffer {
  background-color: #6b0400;
  transition: width 200ms ease-out;
}
.vp-progress__range--current {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #cd131c;
}
.vp-progress__range--current__thumb {
  position: absolute;
  right: 0;
  width: clamp(1.5rem, 3vw, 2.5rem);
  height: clamp(1.5rem, 3vw, 2.5rem);
  border-radius: 50px;
  background-color: #cd131c;
  transform: translateX(50%) scale(0);
  transition: transform 200ms ease-out;
}
.vp-progress__range--seek {
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.vp-progress__range:hover .vp-progress__range--current__thumb {
  transform: translateX(50%) scale(1);
}
.vp-progress__tooltip {
  position: absolute;
  bottom: clamp(4rem, 5vw, 5rem);
  padding: 0.5rem 0.75rem;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  font-weight: 700;
  pointer-events: none;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity 200ms ease-out;
}
.vp-progress:hover .vp-progress__tooltip {
  opacity: 1;
}

.vp-time {
  width: clamp(10rem, 20%, 20rem);
  text-align: center;
}

.vp-volume {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 12rem;
  height: 100%;
}
.vp-volume button {
  width: max-content;
  transition: transform 200ms ease-out;
}
.vp-volume:hover button {
  transform: translateX(clamp(-5rem, -4vw, -2.5rem));
}
.vp-volume:hover .vp-volume__range {
  transform: scaleX(1);
}
.vp-volume__range {
  position: absolute;
  display: flex;
  align-items: center;
  left: 50%;
  width: clamp(6rem, 10vw, 12rem);
  height: clamp(0.5rem, 1vw, 1rem);
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 200ms ease-out;
}

.vp-volume__range--background,
.vp-volume__range--current,
.vp-volume__range--seek {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50px;
}

.vp-volume__range--background {
  background-color: #858585;
}
.vp-volume__range--current {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
}
.vp-volume__range--current__thumb {
  position: absolute;
  right: 0;
  width: clamp(1.5rem, 3vw, 2.5rem);
  height: clamp(1.5rem, 3vw, 2.5rem);
  border-radius: 50px;
  background-color: #fff;
  transform: translateX(50%) scale(0);
  transition: transform 200ms ease-out;
}
.vp-volume__range--seek {
  cursor: pointer;
  opacity: 0;
}
.vp-volume__range:hover .vp-volume__range--current__thumb {
  transform: translateX(50%) scale(1);
}

.vp-dropdown {
  position: absolute;
  bottom: 100%;
  right: 0;
  width: clamp(25rem, 30vw, 40rem);
  overflow: hidden;
  transition: opacity 200ms ease-out, height 300ms ease-out;
  background-color: rgba(0, 0, 0, 0.8);
}
.vp-dropdown__menu {
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: transform 300ms ease;
}
.vp-dropdown__list {
  overflow: auto;
}
.vp-dropdown__label,
.vp-dropdown__item {
  display: flex;
  align-items: center;
  padding: clamp(1rem, 1vw, 2rem) clamp(2rem, 2vw, 4rem);
  gap: 1rem;
  cursor: pointer;
  transition: background-color 200ms;
}
.vp-dropdown__label:hover,
.vp-dropdown__item:hover {
  background-color: rgba(51, 51, 51, 0.8);
}
.vp-dropdown__label {
  font-weight: 600;
}
.vp-dropdown__item {
  justify-content: space-between;
}
.vp-dropdown__item.active::after {
  content: '';
  display: flex;
  width: 1rem;
  height: 1rem;
  border-radius: 50px;
  background-color: #fff;
}

/* CSS Transition */

.vp-dropdown-enter {
  opacity: 0;
}
.vp-dropdown-enter-active {
  opacity: 1;
}
.vp-dropdown-exit-active {
  opacity: 0;
}
.vp-menu-index-enter {
  transform: translateX(-110%);
}
.vp-menu-index-enter-active {
  transform: translateX(0%);
}
.vp-menu-index-exit-active {
  transform: translateX(-110%);
}
.vp-menu-main-enter {
  transform: translateX(110%);
}
.vp-menu-main-enter-active {
  transform: translateX(0%);
}
.vp-menu-main-exit-active {
  transform: translateX(110%);
}
